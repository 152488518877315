/*
 * Postfacto, a free, open-source and self-hosted retro tool aimed at helping
 * remote teams.
 *
 * Copyright (C) 2016 - Present Pivotal Software, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 *
 * it under the terms of the GNU Affero General Public License as
 *
 * published by the Free Software Foundation, either version 3 of the
 *
 * License, or (at your option) any later version.
 *
 *
 *
 * This program is distributed in the hope that it will be useful,
 *
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 *
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *
 * GNU Affero General Public License for more details.
 *
 *
 *
 * You should have received a copy of the GNU Affero General Public License
 *
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'palette';
@import 'settings';

.registration-page {
  background-color: $white;

  h1, p {
    margin: 0 0 2*$global-margin 0;
  }

  p, label {
    font-size: 17px;
    white-space: normal;
    line-height: 1.3;
  }

  .terms-text {
    margin-top: 52px;
    font-size: 14px;

    a {
      color: $happy;
    }
  }


  .access-instruction {
    font-size: 1rem;
    font-style: italic;
    line-height: 1.5rem;
    padding-bottom: 16px;
    padding-top: 8px;
  }

  .registration-side-banner {
    background-color: $meh;
    color: $white;
    text-align: center;
    line-height: 3rem;

    h1 {
      font-size: 2.5rem;
    }

    @media all and (max-width: map-get($breakpoints, medium)) {
      padding: 2.2rem;
      line-height: 2rem;

      h1 {
        font-size: 1.5rem;
      }
    }

    div {
      height: 100vh;
      padding-top: 20vh;

      @media all and (max-width: map-get($breakpoints, medium)) {
        height: unset;
        padding-top: unset;
      }
    }
  }

  .new-retro-column {
    display: flex;
    height: 100%;
    min-height: 53rem;
    padding: 3.5rem 2rem;

    @media all and (max-width: map-get($breakpoints, medium)) {
      height: unset;
      min-height: unset;
      padding: 2rem;
    }

    .row {
      padding-bottom: 1.5rem;
    }
  }

  .retro-form-submit {
    font-size: 1.1rem;
    min-width: 10em;
  }

  .retro-url-prefix {
    background: inherit;
    padding: 0;
    color: $secondary;
  }

  .retro-url {
    margin-bottom: 0;
  }

  .password-terms-text {
    margin-top: 15px;
    font-size: 0.9rem;

    a {
      color: $input-happy;
    }
  }
}
