/*
 * Postfacto, a free, open-source and self-hosted retro tool aimed at helping
 * remote teams.
 *
 * Copyright (C) 2016 - Present Pivotal Software, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 *
 * it under the terms of the GNU Affero General Public License as
 *
 * published by the Free Software Foundation, either version 3 of the
 *
 * License, or (at your option) any later version.
 *
 *
 *
 * This program is distributed in the hope that it will be useful,
 *
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 *
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *
 * GNU Affero General Public License for more details.
 *
 *
 *
 * You should have received a copy of the GNU Affero General Public License
 *
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'palette';

.list-retros-page {
  .new-retro {
    height: 36px;
    margin-right: 15px;
  }

  .retro-tiles-container {
    display: flex;
    flex: 1;
    padding-bottom: 25px;
    background: $light-brown-background;
  }

  .retro-tiles {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 506px;
    padding-top: 38px;

    .retro-list-tile {
      background: $white;
      cursor: pointer;
      line-height: 56px;
      font-size: 18px;
      margin: 11px auto auto;
      letter-spacing: 0.3px;
      width: 90%;

      &:hover {
        background: $tile-hover;
        color: $white;
      }
    }
  }
}
