/*
 * Postfacto, a free, open-source and self-hosted retro tool aimed at helping
 * remote teams.
 *
 * Copyright (C) 2016 - Present Pivotal Software, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 *
 * it under the terms of the GNU Affero General Public License as
 *
 * published by the Free Software Foundation, either version 3 of the
 *
 * License, or (at your option) any later version.
 *
 *
 *
 * This program is distributed in the hope that it will be useful,
 *
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 *
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *
 * GNU Affero General Public License for more details.
 *
 *
 *
 * You should have received a copy of the GNU Affero General Public License
 *
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'palette';

.retro-archives {
  .retro-heading {
    width: 100%;

    .back-button {
      padding-left: 0;
    }

    .menu {
      padding-right: 0;
    }

    .archives-title {
      padding-top: 0.5em;
    }
  }

  .archives {
    background-color: #f2eee2;
    padding-top: 1em;

    .row {
      width: 100%;
    }

    flex: 1;
    display: flex;
    flex-direction: column;

    .archive-row {
      padding: 0.3em;
    }

    .archive-link {
      background-color: $white;
      padding: 0.6em;
      cursor: pointer;
    }
  }

  .retro-menu {
    padding: 16px 16px 0 0;
    float: right;
  }
}
