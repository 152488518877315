/*
 * Postfacto, a free, open-source and self-hosted retro tool aimed at helping
 * remote teams.
 *
 * Copyright (C) 2016 - Present Pivotal Software, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 *
 * it under the terms of the GNU Affero General Public License as
 *
 * published by the Free Software Foundation, either version 3 of the
 *
 * License, or (at your option) any later version.
 *
 *
 *
 * This program is distributed in the hope that it will be useful,
 *
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 *
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *
 * GNU Affero General Public License for more details.
 *
 *
 *
 * You should have received a copy of the GNU Affero General Public License
 *
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

$light-brown-background: #f2eee2;
$archive-background: $light-brown-background;
$primary-red: #e34e40;

$happy: #51c0b1;
$meh: #fec722;
$sad: $primary-red;
$notification: #a0e4db;
$action: $light-brown-background;

$tab-happy: #51c0b1;
$tab-meh: #fec722;
$tab-sad: $primary-red;
$tab-action: #dbd7cb;

$input-happy: #2B9E8E;
$input-meh: #FDAF13;
$input-sad: #D72D25;
$input-action: #DBD7CB;

$lightest-gray: #f1f1f1;
$lighter-gray: #bbbbbb;
$normal-gray: #9c9c9c;

$secondary: gray;
$white: #ffffff;
$light-black: #434343;
$button: #50E3C2;
$button-red: #E54D3A;
$button-red-hover: #F00000;

$page-heading-background: #4BC1B1;

$tile-hover: $happy;

$highlight: #2199E8;
